<template>
  <div>
    <!--begin::User-->
    <div>
      <h3 class="card-label">
        {{ $t('chart_accounts.chart_accounts') }}
        <span class="text-muted pt-2 font-size-sm d-block"></span>
      </h3>

      <div class="row">
        <div class="col-lg-6">
          <div class="card card-custom">
            <div class="card-body row">
              <div class="col-lg-6 mb-5">
                <label>{{ $t('chart_accounts.name') }}<span class="text-danger">*</span></label>
                <input type="text" v-model="data.name" class="form-control" :class="validation && validation.name ? 'is-invalid' : ''"/>
                <span v-if="validation && validation.name" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.name[0] }}
                            </span>
              </div>
              <div class="col-lg-6 mb-5">
                <label>{{ $t('chart_accounts.account_parent') }}</label>
                <treeselect
                    :options="accounts_list"
                    :load-options="loadOptions"
                    :multiple="false"
                    :value="data.parent_id"
                    @input="updateValue"
                    :searchable="true"
                    :class="validation && validation.parent_id ? 'is-invalid' : ''"
                    :show-count="true"
                    :no-children-text="$t('No_sub_options')"
                    :no-options-text="$t('No_options_available')"
                    :no-results-text="$t('No_results_found')"
                    :placeholder="$t('Select')">
                </treeselect>
                <span v-if="validation && validation.parent_id" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.parent_id[0] }}
                            </span>
              </div>
            </div>
          </div>
          <div class="card card-custom mt-5">
            <div class="card-body row">
              <div class="col-lg-6 mb-5">
                <label>{{ $t('chart_accounts.type_account') }}<span class="text-danger">*</span></label>
                <select name="" id="type_account" v-model="data.type_account" class="custom-select" :class="validation && validation.type_account ? 'is-invalid' : ''">
                  <option v-for="row in account_type_list" :value="row.id" :key="row.id">
                    {{ row.title }}
                  </option>
                </select>
                <span v-if="validation && validation.type_account" class="fv-plugins-message-container invalid-feedback">
                                    {{ validation.type_account[0] }}
                                </span>
              </div>
              <div class="col-lg-6 mb-5">
                <label>{{ $t('chart_accounts.code') }}</label>
                <input type="text" v-model="data.code" disabled class="form-control" :class="validation && validation.code ? 'is-invalid' : ''"/>
                <span v-if="validation && validation.code" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.code[0] }}
                            </span>
              </div>
              <div class="col-lg-6 mb-5">
                <label>{{ $t('chart_accounts.serial_from') }}</label>
                <input type="text" v-model="data.serial_from" class="form-control" :class="validation && validation.serial_from ? 'is-invalid' : ''"/>
                <span v-if="validation && validation.serial_from" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.serial_from[0] }}
                            </span>
              </div>
              <div class="col-lg-6 mb-5">
                <label>{{ $t('chart_accounts.serial_to') }}</label>
                <input type="text" v-model="data.serial_to" class="form-control" :class="validation && validation.serial_to ? 'is-invalid' : ''"/>
                <span v-if="validation && validation.serial_to" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.serial_to[0] }}
                            </span>
              </div>
            </div>
          </div>
          <div class="card card-custom mt-5">
            <div class="card-body row">
              <div class="col-lg-6 mb-5">
                <label>{{ $t('chart_accounts.custom_type') }}</label>
                <select name="" id="custom_type" v-model="data.custom_type" class="custom-select" :class="validation && validation.custom_type ? 'is-invalid' : ''">
                  <option value="">{{ $t('nothing') }}</option>
                  <option v-for="row in custom_type_list" :value="row.id" :key="row.id">
                    {{ row.title }}
                  </option>
                </select>
                <span v-if="validation && validation.custom_type" class="fv-plugins-message-container invalid-feedback">
                                    {{ validation.custom_type[0] }}
                                </span>
              </div>
              <div class="col-lg-6 mb-5">
                <label>{{ $t('chart_accounts.custom_type_name') }}</label>
                <multiselect v-model="custom_type_name_object"
                             :placeholder="$t('chart_accounts.custom_type_name')"
                             label="name"
                             track-by="id"
                             :options="custom_type_name_list"
                             :multiple="false"
                             :class="validation && validation.custom_type_id ? 'is-invalid' : ''"
                             :taggable="false"
                             :show-labels="false"
                             :show-no-options="false"
                             :show-no-results="false">
                </multiselect>
                <span v-if="validation && validation.custom_type_id" class="fv-plugins-message-container invalid-feedback">
                                    {{ validation.custom_type_id[0] }}
                                </span>
              </div>
              <div class="col-lg-6 mb-5" v-if="data.custom_type == 4 || data.custom_type == 5">
                <label>&ensp;</label>
                <b-form-checkbox size="lg" v-model="data.use_multi_currency" :class="validation && validation.use_multi_currency ? 'is-invalid' : ''" name="check-button" switch>{{ $t('use_multi_currency') }}</b-form-checkbox>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="card card-custom">
            <div class="card-body row">
              <div class="col-lg-6 mb-5">
                <label>{{ $t('chart_accounts.natural_account') }}<span class="text-danger">*</span></label>
                <select name="" id="natural_account" v-model="data.natural_account" class="custom-select" :class="validation && validation.natural_account ? 'is-invalid' : ''">
                  <option v-for="row in accounts_natural_list" :value="row.id" :key="row.id">
                    {{ row.title }}
                  </option>
                </select>
                <span v-if="validation && validation.natural_account" class="fv-plugins-message-container invalid-feedback">
                                    {{ validation.natural_account[0] }}
                                </span>
              </div>
              <div class="col-lg-6 mb-5">
                <label>{{ $t('chart_accounts.final_account') }}<span class="text-danger">*</span></label>
                <select name="" id="final_account" v-model="data.final_account" class="custom-select" :class="validation && validation.final_account ? 'is-invalid' : ''">
                  <option v-for="row in final_account_list" :value="row.id" :key="row.id">
                    {{ row.title }}
                  </option>
                </select>
                <span v-if="validation && validation.final_account" class="fv-plugins-message-container invalid-feedback">
                                    {{ validation.final_account[0] }}
                                </span>
              </div>
              <div class="col-lg-6 mb-5">
                <label>{{ $t('chart_accounts.currency') }}<span class="text-danger">*</span></label>
                <select name="" id="currency_id" v-model="data.currency_id" class="custom-select" :class="validation && validation.currency_id ? 'is-invalid' : ''">
                  <option v-for="row in currencies" :value="row.id" :key="row.id">
                    {{ row.name }}
                  </option>
                </select>
                <span v-if="validation && validation.currency_id" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.currency_id[0] }}
                            </span>
              </div>
              <div class="col-lg-6 mb-5">
                <label>{{ $t('chart_accounts.amount') }}<span class="text-danger">*</span></label>
                <input type="number" v-model="data.amount" :disabled="disabled_amount" step="0.01" min="0" class="form-control" :class="validation && validation.amount ? 'is-invalid' : ''"/>
                <span v-if="validation && validation.amount" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.amount[0] }}
                            </span>
              </div>
              <div class="col-lg-6 mb-5">
                <label>{{ $t('chart_accounts.branch') }}</label>
                <multiselect v-model="branch"
                             :placeholder="$t('chart_accounts.branch')"
                             label="name"
                             track-by="id"
                             :options="branches"
                             :multiple="false"
                             :class="validation && validation.branch_id ? 'is-invalid' : ''"
                             :taggable="true"
                             :show-labels="false"
                             :show-no-options="false"
                             :show-no-results="false">
                </multiselect>
                <span v-if="validation && validation.branch_id" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.branch_id[0] }}
                            </span>
              </div>
              <div class="col-lg-6 mb-5">
                <label>{{ $t('chart_accounts.inventory') }}</label>
                <multiselect v-model="inventory"
                             :placeholder="$t('chart_accounts.inventory')"
                             label="name"
                             track-by="id"
                             :options="inventories"
                             :multiple="false"
                             :class="validation && validation.inventory_id ? 'is-invalid' : ''"
                             :taggable="true"
                             :show-labels="false"
                             :show-no-options="false"
                             :show-no-results="false">
                </multiselect>
                <span v-if="validation && validation.inventory_id" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.inventory_id[0] }}
                            </span>
              </div>

              <div class="col-lg-6 mb-5">
                <label>&ensp;</label>
                <b-form-checkbox size="lg" v-model="data.is_active" :class="validation && validation.is_active ? 'is-invalid' : ''" name="check-button" switch>{{ $t('status') }}</b-form-checkbox>
                <span v-if="validation && validation.is_active" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.is_active[0] }}
                            </span>
              </div>
            </div>
          </div>
          <div class="card card-custom mt-5">
            <div class="card-body row">
              <div class="col-lg-12 mb-5">
                <label>{{ $t('chart_accounts.notes') }}</label>
                <textarea type="text" v-model="data.notes" class="form-control" :class="validation && validation.notes ? 'is-invalid' : ''"></textarea>
                <span v-if="validation && validation.notes" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.notes[0] }}
                            </span>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div class="pl-0 pr-0">
        <div class="row">
          <div class="col-lg-6 mt-10">
            <button type="button" class="btn btn-primary mr-2" @click="save">{{ $t('save') }}</button>
          </div>
        </div>
      </div>

    </div>
    <!--end::User-->
  </div>
</template>

<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";

export default {
  name: "from-chart-accounts",
  data() {
    return {
      mainRoute: 'accounting/chart-accounts',
      subMainRoute: 'accounting/chart-account',
      mainRouteDependency: 'base/dependency',

      idEdit: this.$route.params.id ? this.$route.params.id : null,

      data: {
        name: null,
        serial_from: null,
        serial_to: null,
        parent_id: null,
        final_account: null,
        type_account: null,
        currency_id: null,
        natural_account: null,
        custom_type: null,
        custom_type_id: null,
        amount: '0',
        notes: null,
        code: null,
        is_active: true,
        inventory_id: null,
        branch_id: null,
        // exchange_rate : null,
        use_multi_currency: false,
      },
      isEditing: false,
      show_message_confirm: false,
      validation: null,
      inventory: null,
      branch: null,
      custom_type_name_object: null,

      accounts_list: [],
      accounts_natural_list: [],//Credit - Debit
      final_account_list: [],//Profit & loss    -    trading account
      account_type_list: [], // Collected  - simple
      currencies: [],
      custom_type_list: [],//(vendor – customer – inventory - treasury - bank )
      custom_type_name_list: [],
      inventories: [],
      branches: [],
      treasuries: [],
      disabled_amount: false,
    };
  },
  watch: {
    'data.custom_type': function (val) {
      this.custom_type_name_list = [];
      if (val) {
        this.getCustomTypeName();
      } else {
        this.data.custom_type_id = null;
      }
    },
    "data.amount": function (val) {
      if (!val) {
        this.data.amount = '0';
      }
    },
    custom_type_name_object: function (val) {
      if (val) {
        this.data.custom_type_id = val.id;
      } else {
        this.data.custom_type_id = null;
      }
    },
    'data.type_account': function (val, oldValue) {
      if (val == 1) {
        if (!this.isEditing || (oldValue == 2)) {
          this.getSerialFromTo();
        }
      } else {
        this.data.serial_from = null;
        this.data.serial_to = null;
        this.getAccountCode();
      }
    },
    inventory: function (val) {
      if (val) {
        this.data.inventory_id = val.id;
      } else {
        this.data.inventory_id = null;
      }
    },
    branch: function (val) {
      if (val) {
        this.data.branch_id = val.id;
      } else {
        this.data.branch_id = null;
      }
    },
  },
  methods: {
    save() {
      if (this.show_message_confirm) {
        if (!this.isEditing) {
          this.$confirmAlert(this.$t('chart_accounts.the_old_account_contains_a_value'), this.create, null);
          return false;
        }
      }
      if (this.isEditing) {
        this.update();
      } else {
        this.create();
      }
    },

    create() {
      ApiService.post(this.mainRoute, {
        ...this.data,
      }).then((response) => {
        this.validation = null;
        this.$successAlert(response.data.message);
        this.$router.push('/accounting/chart-accounts');
      }).catch((error) => {
        this.$errorAlert(error);
        this.validation = error.response ? error.response.data.errors : null;
      });
    },

    update() {
      ApiService.put(this.mainRoute + '/' + this.idEdit, {
        ...this.data,
      }).then((response) => {
        this.validation = null;
        this.$successAlert(response.data.message);
        this.$router.push('/accounting/chart-accounts');
      }).catch((error) => {
        this.$errorAlert(error);
        this.validation = error.response ? error.response.data.errors : null;
      });
    },


    getData() {
      ApiService.get(this.mainRoute + '/' + this.idEdit).then((response) => {
        this.isEditing = true;
        this.data.name = response.data.data.name;
        this.data.serial_from = response.data.data.serial_from;
        this.data.serial_to = response.data.data.serial_to;
        this.data.parent_id = response.data.data.parent_id;
        this.data.final_account = response.data.data.final_account;
        this.data.type_account = response.data.data.type_account;
        this.data.currency_id = response.data.data.currency_id;
        this.data.natural_account = response.data.data.natural_account;
        this.data.custom_type = response.data.data.custom_type;
        this.data.custom_type_id = response.data.data.custom_type_id;
        this.data.amount = response.data.data.amount ? response.data.data.amount : '0';
        this.data.notes = response.data.data.notes;
        this.data.is_active = response.data.data.is_active;
        this.data.code = response.data.data.code;
        this.data.invoice_id = response.data.data.invoice_id;
        this.data.branch_id = response.data.data.branch_id;
        // this.data.exchange_rate = response.data.data.exchange_rate;

        this.data.inventory_id = response.data.data.inventory_id;
        this.data.branch_id = response.data.data.branch_id;

        this.inventory = response.data.data.inventory;
        this.branch = response.data.data.branch;

        if (response.data.data.amount && parseFloat(response.data.data.amount) != 0) {
          this.disabled_amount = true;
        }
      });
    },
    getParentData() {
      ApiService.get(this.mainRoute + '/' + this.$route.params.parent_id).then((response) => {
        this.data.amount = response.data.data.amount ? response.data.data.amount : '0';
        if (response.data.data.amount) {
          this.show_message_confirm = true;
        }
      });
    },
    getSerialFromTo() {
      ApiService.get(this.subMainRoute + '/get-serial-from-to', {params: {parent_id: this.data.parent_id}}).then((response) => {
        this.data.serial_from = response.data.data.serial_from;
        this.data.serial_to = response.data.data.serial_to;
        this.data.code = response.data.data.serial_from;
      }).catch((error) => {
        this.$errorAlert(error);
      });
    },
    getAccountCode() {
      ApiService.get(this.subMainRoute + '/get-serial-code', {params: {parent_id: this.data.parent_id}}).then((response) => {
        this.data.code = response.data.data.code;
      }).catch((error) => {
        this.$errorAlert(error);
      });
    },
    getCurrencies() {
      ApiService.get(this.mainRouteDependency + "/currencies").then((response) => {
        this.currencies = response.data.data;
      });
    },

    getNaturalAccount() {
      ApiService.get(this.mainRouteDependency + "/natural_account").then((response) => {
        this.accounts_natural_list = response.data.data;
      });
    },
    getFinalAccount() {
      ApiService.get(this.mainRouteDependency + "/final_account").then((response) => {
        this.final_account_list = response.data.data;
      });
    },
    getAccountType() {
      ApiService.get(this.mainRouteDependency + "/account_type").then((response) => {
        this.account_type_list = response.data.data;
      });
    },
    getCustomType() {
      ApiService.get(this.mainRouteDependency + "/custom_type").then((response) => {
        this.custom_type_list = response.data.data;
      });
    },
    getCustomTypeName() {
      ApiService.get(this.mainRouteDependency + "/custom_types/" + this.data.custom_type).then((response) => {
        this.custom_type_name_list = response.data.data;
        if (this.data.custom_type_id) {
          this.custom_type_name_object = response.data.data.find(row => row.id == this.data.custom_type_id);
        }
      });
    },
    getInventories() {
      ApiService.get(this.mainRouteDependency + "/inventories").then((response) => {
        this.inventories = response.data.data;
      });
    },
    getBranches() {
      ApiService.get(this.mainRouteDependency + "/branches").then((response) => {
        this.branches = response.data.data;
      });
    },
    // accounts_list
    updateValue(value) {
      this.data.parent_id = value
    },
    loadOptions() {
    },
    getAccountParents() {
      ApiService.get(`${this.mainRouteDependency}/${'account_parents'}`).then((response) => {
        this.accounts_list = response.data.data;
      });
    },

  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t("MENU.chart_accounts"), route: '/accounting/chart-accounts'}, {title: (this.idEdit ? this.$t('update') : this.$t('create'))}]);

    this.getCurrencies();
    this.getNaturalAccount();
    this.getFinalAccount();
    this.getAccountType();
    this.getCustomType();
    this.getAccountParents();
    this.getInventories();
    this.getBranches();

    this.data.parent_id = this.$route.params.parent_id ? this.$route.params.parent_id : null;
    if (this.$route.params.parent_id) {
      this.getParentData();
    }
    if (this.idEdit) {
      this.getData();
    }

  },
};
</script>


